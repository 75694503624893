<template>
  <div class="pr">
    <div class="pr-header">
      <img loading="lazy" class="pr-logo" :src="'/logo/logo-premium-light.svg'" alt="Premium Logo" />
    </div>

    <h2>You have a Premium subscription!</h2>
    <p>Your subscription expires on <strong>December 31, 2025</strong>.</p>

    <UiButton
      :to="'/premium'"
      :name="'Extend'"
      :type="'primary'"
      tag="NuxtLink"
      />      

    <div class="pr-links">
      <NuxtLink :to=" user.link + '/settings/invoices'" class="pr-link" href="#">Invoices</NuxtLink>
      <NuxtLink to="/premium" class="pr-link" href="#">Premium Options Description</NuxtLink>
    </div>
  </div>
</template>


<script setup lang="ts">

import type { User } from '@/types/user'

const route = useRoute();
const { $api, $ga, $helper, $head } = useNuxtApp();

interface Props {
  user: User;
  id: number;
}

const props = defineProps<Props>();

const loadData = async (id: number) => {
  return await $api.getGrowerInfoPremium(id);  
}

// const { status: statusUserInfoPremiumData,  data: userInfoPremiumData } = await useLazyAsyncData('userInfoPremiumData', async () => await loadData(props.id))



</script>




<style scoped>
.pr {
  border-radius: 1rem;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
  box-shadow: 0 0 10px #00000017;
  margin-top: 2rem;
  border: 3px #f4b4235c solid;
  margin-bottom: 2rem;
}

.pr-header {
  margin-bottom: 1rem;
}

.pr-logo {
  width: 210px;
  height: auto;
}

h2{
  font-size: 1.5rem;
  margin: 0;
}

.pr-extend-btn {
  padding: 10px 20px;
  margin: 15px 0;
  border: none;
  cursor: pointer;
  background-color: #ccc;
  font-size: 1rem;
}

.pr-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.pr-link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
